import React from 'react';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';
import { Metadata } from '@latitude/metadata';
import { SecondaryHero } from '@latitude/hero';
import { COLOR } from '@latitude/core/utils/constants';
import { Text } from '@latitude/text';
import { Heading4, Heading5 } from '@latitude/heading';
import { SITE_URL } from '../../utils/constants';
import Layout from '../../components/layout';

const UploadPage = ({ location }) => (
  <Layout location={location}>
    <main className="navigation-spacer">
      <Metadata
        title="Complaints | Gem by Latitude "
        description="Please talk to us if you have any concerns, so we can help fix the problem. "
        canonical={`${SITE_URL}${location.pathname}`}
      />

      <SecondaryHero
        heading="Complaints"
        subHeading="We know that sometimes things don't go as well as they should and problems arise. If this happens to you please let us know so we can fix the problem."
      />

      <Box.Section
        backgroundColor={COLOR.GREY6}
        css={`
          max-width: 690px;
        `}
      >
        <Heading4>Please talk to us first</Heading4>
        <Text>
          Once we are aware of your concern we expect all our staff will take
          the appropriate steps to address your concerns straight away.
          <br />
          <br /> If the staff member is not able to resolve this with you the
          matter will be referred to our Customer Resolutions team. Customer
          Resolutions will investigate the issue and take all reasonable steps
          to resolve the matter with you or your authorised representative.
        </Text>
        <Heading5
          css={`
            margin: 30px 0 10px;
          `}
        >
          Contact the Customer Resolutions team:
        </Heading5>
        <Text>
          Latitude Financial Services Limited
          <br /> PO Box 4058 Shortland Street
          <br />
          Auckland 1140
        </Text>
        <Heading5
          css={`
            margin: 30px 0 10px;
          `}
        >
          Phone
        </Heading5>
        <Text
          css={`
            margin-bottom: 15px;
          `}
        >
          0800 500 505 (New Zealand)
          <br /> +64 800 500 505 (International)
        </Text>
        <Text
          css={`
            margin-bottom: 15px;
          `}
        >
          If you wish to raise your complaint online, please complete the form
          below:
          <br />
          <Link href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=360003620838&_ga=2.107843072.384844303.1606873120-673997273.1604555169">
            Online Complaint Form
          </Link>
        </Text>
        <Text>
          If you believe your complaint has not been resolved, you may want to
          refer the matter to an external dispute resolution service. We are a
          member of the{' '}
          <strong>Insurance &amp; Financial Services Ombudsman Scheme</strong>{' '}
          (IFSO). This scheme independently and impartially resolves disputes
          between consumers and participating financial services providers and
          is free to consumers.
        </Text>
        <Heading5
          css={`
            margin: 30px 0 10px;
          `}
        >
          Insurance &amp; Financial Services Ombudsman Scheme
        </Heading5>
        <Text>
          PO Box 10-845
          <br /> Wellington 6143
          <br /> Phone: (04) 499 7612
          <br /> Email: <Link href="mailto:info@ifso.nz">info@ifso.nz</Link>
          <br />
          Website: <Link href="https://www.ifso.nz">www.ifso.nz</Link>
        </Text>
      </Box.Section>
    </main>
  </Layout>
);

export default UploadPage;
